var exports = {};
exports.G = "G";
exports.Svg = "SVG";
exports.View = "VIEW";
exports.Text = "TEXT";
exports.Link = "LINK";
exports.Page = "PAGE";
exports.Note = "NOTE";
exports.Path = "PATH";
exports.Rect = "RECT";
exports.Line = "LINE";
exports.Stop = "STOP";
exports.Defs = "DEFS";
exports.Image = "IMAGE";
exports.Tspan = "TSPAN";
exports.Canvas = "CANVAS";
exports.Circle = "CIRCLE";
exports.Ellipse = "ELLIPSE";
exports.Polygon = "POLYGON";
exports.Document = "DOCUMENT";
exports.Polyline = "POLYLINE";
exports.ClipPath = "CLIP_PATH";
exports.TextInstance = "TEXT_INSTANCE";
exports.LinearGradient = "LINEAR_GRADIENT";
exports.RadialGradient = "RADIAL_GRADIENT";
export default exports;
export const G = exports.G,
      Svg = exports.Svg,
      View = exports.View,
      Text = exports.Text,
      Link = exports.Link,
      Page = exports.Page,
      Note = exports.Note,
      Path = exports.Path,
      Rect = exports.Rect,
      Line = exports.Line,
      Stop = exports.Stop,
      Defs = exports.Defs,
      Image = exports.Image,
      Tspan = exports.Tspan,
      Canvas = exports.Canvas,
      Circle = exports.Circle,
      Ellipse = exports.Ellipse,
      Polygon = exports.Polygon,
      Document = exports.Document,
      Polyline = exports.Polyline,
      ClipPath = exports.ClipPath,
      TextInstance = exports.TextInstance,
      LinearGradient = exports.LinearGradient,
      RadialGradient = exports.RadialGradient;